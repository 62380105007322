import $ from "jquery";

import gsap from "gsap";

var contClicks = 0;
$('.header-bars').on('click', function () {
    contClicks++

    $('html').toggleClass('no-scroll')
    $('header').toggleClass('border-bottom-none')
    $('.container-header').toggleClass('color-header-transparent')
    $('#progressBarContainer').toggleClass('d-none')
    $('#progressBar').toggleClass('d-none')
    $('.header-lang').toggleClass('d-none')
    $('.main-menu').toggleClass('d-flex')
    $('.header-bars-line1').toggleClass('header-bars-line1-active')
    $('.header-bars-line2').toggleClass('header-bars-line2-active')
    $('.header-bars-line3').toggleClass('header-bars-line3-active')

    if (contClicks == 1) {
        const line = document.querySelectorAll('.line-anim-one')

        line.forEach((item) => {

            gsap.from(item, {
                scaleX: 0,
                duration: 1,
                ease: 'none',
            })
        })
    }
})

$('.header-lang').on('click', function () {
    if (screen.width < 768) {
        $('html').toggleClass('no-scroll')
        $('.container-language-mov').css('display', 'block');
    }
})

$('.gtranslate_wrapper').on('click', function () {
    if (screen.width < 768) {
        $('html').toggleClass('no-scroll')
        $('.container-language-mov').css('display', 'none');
    }
})