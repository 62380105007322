import Swiper from 'swiper/bundle';

const swiper = new Swiper('.swiper-main', {
    // Optional parameters
    loop: true,
    effect: "creative",
    creativeEffect: {
        prev: {
            shadow: true,
            origin: "left center",
            translate: ["-5%", 0, -200],
            rotate: [0, 100, 0],
        },
        next: {
            origin: "right center",
            translate: ["5%", 0, -200],
            rotate: [0, -100, 0],
        },
    },

    // Autoplay
    autoplay: {
        delay: 5000,
        disableOnInteraction: true,
    },
});

