import gsap, { wrap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import TextPlugin from "gsap/TextPlugin"
import Observer from "gsap/Observer"

import $ from "jquery";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, TextPlugin, Observer)

$('.active-pop-up').on('click', async function (e) {
    e.preventDefault()

    $('html').css('overflow-y', 'hidden')
    $('.pop-up').css('display', 'flex')
    $('.pop-up-container').slideDown('slow')

    let post_id = this.getAttribute('data-post-id')
    let postName = this.getAttribute('data-post-name')
    let postType = this.getAttribute('data-type')

    if (window.location.origin == 'https://bokbrands.com') {
        var url = window.location.origin + '/public'
    } else {
        var url = window.location.origin
    }

    //Api
    const response = await fetch(url + '/wp-json/api/v3/' + postType + '?post_id=' + post_id)
    const post = await response.json()

    let header = $('.pop-up-header');

    if (postType == 'affiliates') {
        var img = document.createElement('img')
        img.classList.add('pop-up-header-logo')
        header.append(img)

        $('.pop-up-header-logo').attr('src', post.logo)
        post.rnt ? $('.rnt').text('RNT: ' + post.rnt) : ''

        if (post.rnt || post.facebook || post.instagram) {
            $('.social-pop').css('display', 'flex')
        }

        if (post.facebook) {
            $('.pop-up-social-fc').attr('href', post.facebook)
            $('.pop-up-social-fc').css('display', 'initial')
        }

        if (post.instagram) {
            $('.pop-up-social-in').attr('href', post.instagram)
            $('.pop-up-social-in').css('display', 'initial')
        }

    }

    if (postType == 'experiences' || postType == 'towns') {
        var h3 = document.createElement('h3')
        h3.classList.add('pop-up-header-title', 'title-pop-up')
        header.append(h3)

        if (postType == "towns") {
            $('.pop-up-header-title').text('Experiencias en ' + post.title)
            $('.pop-up-header-title').css('width', '100%')
        } else {
            $('.pop-up-header-title').text(post.title)
        }
    }

    if (postType == 'affiliates' || postType == 'experiences') {
        var div = document.createElement('div')
        div.classList.add('pop-up-header-conatiner-button')
        header.append(div)

        var a = document.createElement('a')
        a.classList.add('pop-up-header-button', 'blue-dark-button', 'button-text')
        div.append(a)

        $('.pop-up-header-button').attr('href', post.link.url)
        $('.pop-up-header-button').attr('target', post.link.target)
        if (post.link.title == '') {
            $('.pop-up-header-button').text('Reserve aquí')
        } else {
            $('.pop-up-header-button').text(post.link.title)
        }
    }

    //Images
    post.images.forEach(function (image, i) {
        let contImage = document.createElement('div')
        contImage.classList.add('pop-up-container-image')
        let outer = document.createElement('div')
        outer.classList.add('outer-pop')
        let inner = document.createElement('div')
        inner.classList.add('inner-pop')
        let imag = document.createElement('img')
        imag.classList.add('bg-pop', 'bg-pop-' + i)
        let ani = $('.scroll-ani-two')
        ani.append(contImage)
        contImage.append(outer)
        outer.append(inner)
        inner.append(imag)
        $('.bg-pop-' + i).attr('src', 'image')
        $('.bg-pop-' + i).attr('src', image)
    })


    //Description
    let footer = $('.pop-up-footer-container-description')
    let p = document.createElement('p')
    p.classList.add('pop-up-footer-description', 'text-pop-up')
    footer.append(p)
    $('.pop-up-footer-description').html(post.description)

    //Share
    // $('.pop-up-share-fc').attr('href', 'http://facebook.com/sharer/sharer.php?u=' + url + '/' + post.type + '/' + postName)
    // $('.pop-up-share-tw').attr('href', 'http://twitter.com/share?text=' + post.title + '&url=' + url + '/' + post.type + '/' + postName)
    // $('.pop-up-share-wp').attr('href', 'https://api.whatsapp.com/send?text=' + post.title + '%20' + url + '/' + post.type + '/' + postName)

    $('.loading-body-pop-up').fadeOut('slow')

    //Animation
    if (post.images.length > 1) {
        let sections = document.querySelectorAll(".pop-up-container-image"),
            images = document.querySelectorAll(".bg-pop"),
            outerWrappers = gsap.utils.toArray(".outer-pop"),
            innerWrappers = gsap.utils.toArray(".inner-pop"),
            currentIndex = -1,
            wrap = gsap.utils.wrap(0, sections.length),
            animating;

        gsap.set(outerWrappers, { yPercent: 100 });
        gsap.set(innerWrappers, { yPercent: -100 });

        function gotoSection(index, direction) {
            index = wrap(index);
            animating = true;
            let fromTop = direction === -1,
                dFactor = fromTop ? -1 : 1,
                tl = gsap.timeline({
                    defaults: { duration: 1.25, ease: "power1.inOut" },
                    onComplete: () => animating = false
                });
            if (currentIndex >= 0) {
                gsap.set(sections[currentIndex], { zIndex: 0 });
                tl.to(images[currentIndex], { yPercent: -15 * dFactor })
                    .set(sections[currentIndex], { autoAlpha: 0 });
            }
            gsap.set(sections[index], { autoAlpha: 1, zIndex: 1 });
            tl.fromTo([outerWrappers[index], innerWrappers[index]], {
                yPercent: i => i ? -100 * dFactor : 100 * dFactor
            }, {
                yPercent: 0
            }, 0)
                .fromTo(images[index], { yPercent: 15 * dFactor }, { yPercent: 0 }, 0)

            currentIndex = index;
        }

        Observer.create({
            type: "wheel,touch,pointer",
            target: '.scroll-ani-two',
            wheelSpeed: -1,
            onDown: () => !animating && gotoSection(currentIndex - 1, -1),
            onUp: () => !animating && gotoSection(currentIndex + 1, 1),
            tolerance: 10,
            preventDefault: true
        });

        gotoSection(0, 1);
    }
})

$('.pop-up-close, .pop-up').on('click', () => {
    $('.pop-up-header-logo').remove()
    $('.pop-up-header-title').remove()
    $('.pop-up-header-conatiner-button').remove()
    $('.pop-up-container-image').remove()
    $('.pop-up-footer-description').remove()
    $('.rnt').text('')
    $('.social-pop').css('display', 'none')
    $('.pop-up-social-fc').css('display', 'none')
    $('.pop-up-social-in').css('display', 'none')
    $('html').css('overflow-y', 'initial')
    $('.pop-up-container').slideUp('slow')
    $('.pop-up').fadeOut('slow', function () {
        $('.loading-body-pop-up').css('display', 'flex')
    })

})


$('.pop-up-footer-share').on('click', (e) => {
    e.preventDefault()

    $('.pop-up-share').css('display', 'flex')
})

$('.pop-up-share').on('click', () => {
    $('.pop-up-share').css('display', 'none')
})