import gsap from "gsap";
import TextPlugin from "gsap/TextPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import Observer from "gsap/Observer";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import SplitType from 'split-type'

gsap.registerPlugin(TextPlugin, ScrollTrigger, Observer, ScrollToPlugin);


const animTextTwo = document.querySelectorAll('.anim-text-two')

animTextTwo.forEach((item) => {
    gsap.from(item, {
        duration: 4,
        text: '',
        scrollTrigger: {
            trigger: item,
            start: 'top 70%',
        }
    })
})

const title = document.querySelectorAll('.title-anim-one')

title.forEach((char) => {
    const tit = new SplitType(char, { types: 'lines' })

    gsap.from(tit.lines, {
        scrollTrigger: {
            trigger: char,
            start: 'top 70%',
            end: 'top 50%',
            scrub: false,
            toggleActions: "play none none reverse",
        },
        y: 100,
        opacity: 0,
        duration: 1,
        stagger: 0.6,
        ease: 'back',
    })
})

const img = document.querySelectorAll('.gallery-image-title-image')

img.forEach((item) => {

    gsap.from(item, {
        scrollTrigger: {
            trigger: item,
            start: 'top 80%',
            end: 'bootom top',
            scrub: 7,
            toggleActions: "play none none reverse",
        },
        height: 0,
        opacity: 0,
        duration: 7,
    })
})



const enlarge = document.querySelectorAll('.tgd-enlarge')

enlarge.forEach((item) => {

    gsap.from(item, {
        scrollTrigger: {
            trigger: item,
            start: 'top 80%',
            end: 'bottom 20%',
            scrub: false,
            toggleActions: "play none none reverse",
        },
        y: 200,
        duration: 1,
        ease: 'none',
    })
})

const directors = document.querySelectorAll('.directors')

directors.forEach((item) => {

    gsap.from(item, {
        scrollTrigger: {
            trigger: item,
            start: 'top 50%',
            end: 'bottom 20%',
            scrub: false,
            toggleActions: "play none none reverse",
        },
        y: -300,
        duration: 2,
        ease: 'none',
    })
})

const mapTitle = document.querySelectorAll('.map-code-qr')

mapTitle.forEach((item) => {
    gsap.from(item, {
        scrollTrigger: {
            trigger: item,
            start: 'top 80%',
            end: 'bottom 50%',
            scrub: 1.5,
            toggleActions: "play none none reverse",
        },
        x: -100,
        duration: 1,
        ease: 'none',
    })
})


const line = document.querySelectorAll('.line-anim')

line.forEach((item) => {

    gsap.from(item, {
        scrollTrigger: {
            trigger: item,
            start: 'top 80%',
            end: 'bottom 50%',
            scrub: false,
            toggleActions: "play none none reverse",
        },
        scaleX: 0,
        duration: 1,
        ease: 'none',
    })
})

gsap.utils.toArray(".affiliate-image").forEach((affiliate, i) => {

    let trigger = ScrollTrigger.create({
        trigger: affiliate,
        start: "top top",
        pin: true,
        pinSpacing: false,
        end: "bottom",
    });

});

const showAnim = gsap.from('header', {
    yPercent: -100,
    paused: true,
    duration: 0.3
}).progress(1);

ScrollTrigger.create({
    start: "top top",
    end: 99999,
    onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse()
    }
});


gsap.utils.toArray(".animation-text-one").forEach((item, i) => {

    ScrollTrigger.create({
        trigger: item,
        start: "top 70%",
        onEnter: () => addClassOne(item),
    })

})

const addClassOne = (item) => {
    item.classList.add('animate-one')
}


gsap.utils.toArray(".anim-image-one").forEach((item, i) => {

    gsap.from(item, {
        scrollTrigger: {
            trigger: item,
            start: "-50% 80%",
            markers: true
        },
        height: 200,
        y: -100,
        duration: 2,
        ease: "power1.inOut"
    })


})
