import Swiper from 'swiper/bundle';
import $ from "jquery";

const swiper = new Swiper('.swiper-town', {
    // Optional parameters
    loop: true,
    effect: "fade",

    // Autoplay
    autoplay: {
        delay: 4500,
        disableOnInteraction: false,
    },

    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});
