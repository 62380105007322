import $, { contains } from "jquery";

import '../sass/main.scss'

import 'bootstrap/dist/js/bootstrap.bundle'

// import Swiper JS
import 'swiper/css/bundle'
import 'swiper/css'

import './header/bars'
import './main-banner/main-banner'
import './towns/towns'
import './animation/animation'
import './pop-up/pop-up'

import gsap from "gsap"
import TextPlugin from "gsap/TextPlugin"
import ScrollTrigger from "gsap/ScrollTrigger"
import Observer from "gsap/Observer"

import Swiper from 'swiper/bundle';


gsap.registerPlugin(TextPlugin, ScrollTrigger, Observer);




$(window).on('load', function () {
    $('html').animate({ scrollTop: 0 }, {
        duration: 'slow',
        complete: () => {
            $('html').css('overflow-y', 'initial')
            $('.loading-body').fadeOut('slow')
            AOS.init();

            const swiperTitle = new Swiper('.swiper-title', {
                // Optional parameters
                loop: true,
                effect: "cube",
                cubeEffect: {
                    shadow: false
                },

                // Autoplay
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false,
                },
            });

            const icon = document.querySelectorAll('.main-banner-whatsapp')
            if (icon.length > 0) {
                gsap.from('.main-banner-whatsapp', {
                    duration: 2.5, x: -1500, opacity: 0,
                    duration: 1, delay: 2
                })
            }
        }
    });

    if (window.location.hash !== '') {
        var hash = window.location.hash
        $('html').animate({
            scrollTop: $(hash).offset().top
        }, 800, function () {
            window.location.hash = hash
        })
    }

})

$(window).on('scroll', function () {
    if (this.scrollY > 120) {
        $('.container-header').addClass('color-header')
        $('.languages').addClass('color-header')
    } else {
        $('.container-header').removeClass('color-header')
        $('.languages').removeClass('color-header')
    }

    if (this.scrollY > 2000) {
        $('.button-top').css('z-index', 50)
        $('.button-top').css('opacity', 1)
    } else {
        $('.button-top').css('z-index', -10)
        $('.button-top').css('opacity', 0)
    }
})


$('.button-top').on('click', (e) => {
    e.preventDefault()
    $('html').animate({ scrollTop: 0 }, {
        duration: 'slow'
    })
})


$('.header-lang').on('click', (e) => {
    e.preventDefault()
})


const x = document.getElementsByClassName('scroll-ani-one');

if (x.length > 0) {
    let anim = gsap.utils.toArray('.scroll-ani-one')
    anim.forEach(function (ani) {
        let sections = ani.querySelectorAll(".gallery-image-title-image-scroll"),
            images = ani.querySelectorAll(".bg"),
            outerWrappers = ani.querySelectorAll(".outer"),
            innerWrappers = ani.querySelectorAll(".inner"),
            currentIndex = -1,
            wrap = gsap.utils.wrap(0, sections.length),
            animating;

        gsap.set(outerWrappers, { yPercent: 100 });
        gsap.set(innerWrappers, { yPercent: -100 });

        function gotoSection(index, direction) {
            index = wrap(index);
            animating = true;
            let fromTop = direction === -1,
                dFactor = fromTop ? -1 : 1,
                tl = gsap.timeline({
                    defaults: { duration: 1.25, ease: "power1.inOut" },
                    onComplete: () => animating = false
                });
            if (currentIndex >= 0) {
                gsap.set(sections[currentIndex], { zIndex: 0 });
                tl.to(images[currentIndex], { yPercent: -15 * dFactor })
                    .set(sections[currentIndex], { autoAlpha: 0 });
            }
            gsap.set(sections[index], { autoAlpha: 1, zIndex: 1 });
            tl.fromTo([outerWrappers[index], innerWrappers[index]], {
                yPercent: i => i ? -100 * dFactor : 100 * dFactor
            }, {
                yPercent: 0
            }, 0)
                .fromTo(images[index], { yPercent: 15 * dFactor }, { yPercent: 0 }, 0)

            currentIndex = index;
        }

        Observer.create({
            type: "wheel,touch,pointer",
            target: ani,
            wheelSpeed: -1,
            onDown: () => !animating && gotoSection(currentIndex - 1, -1),
            onUp: () => !animating && gotoSection(currentIndex + 1, 1),
            tolerance: 10,
            preventDefault: true
        });

        gotoSection(0, 1);
    })
}

const progressBar = document.querySelector("#progressBar");
let totalPageHeight = document.body.scrollHeight - window.innerHeight;
window.onscroll = () => {
    let newProgressHeight = (window.pageYOffset / totalPageHeight) * 100;
    progressBar.style.height = `${newProgressHeight}%`;
    progressBar.style.opacity = `1`;
};





